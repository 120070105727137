import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CustomErrorModal from "./sharedComponents/CustomErrorModal";
import TransparentSpinner from "./sharedComponents/TransparentSpinner";
import { post, get } from "aws-amplify/api";
import candadocvv from "../assets/images/candadocvv.png";
import denegated from "../assets/images/denegated.png";
import warningLogo from "../assets/images/warningLogo.png";
import tcFallido from "../assets/images/tcFallido.png";
import { FaCheckCircle } from "react-icons/fa";
// import CaptchaComponent from "../components/sharedComponents/Captcha";

export default function PaymentData() {
  const dispatch = useDispatch();
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [isCaptchaResolved, setIsCaptchaResolved] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [isPaymentInitiated, setIsPaymentInitiated] = useState(false);
  const [terminosTriggerError, setTerminosTriggerError] = useState(false);
  const [terminosYcondiciones, setTerminosYcondiciones] = useState(false);
  const [cashPaymentError, setCashPaymentError] = useState(false);
  const [cashPaymentErrorMessage, setCashPaymentErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const userImei = useSelector((state) => state.imei);
  // const userEmail = useSelector((state) => state.email);
  const policyNumber = useSelector((state) => state.policy);
  const [triggerError, setTriggerError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  let isDist = localStorage.getItem("mtd")
  let regionDistInt = 0
  if (isDist) {
    const jsonDist = JSON.parse(isDist);
    const regionDist = jsonDist.region
    regionDistInt = parseInt(regionDist)
  }
  console.log('regionDistInt2',regionDistInt)
  const rangoMinRegionDist = 1;
  const rangoMaxRegionDist = 8;

  let isUserDataComplete = JSON.parse(
    localStorage.getItem("userDataComplete") || "false"
  );
  const [enabledBTN, setEnabledBTN] = useState(false);

  const [userDataIncomplete, setUserDataIncomplete] = useState();

  const [confirmTcPaymentDetails, setconfirmTcPaymentDetails] = useState({
    tokenPayment: null
  });

  const [creditPaymentForm, setCreditPaymentForm] = useState({
    email: "",
    regionId: 1,
    accountNumber: 0,
    imei: localStorage.getItem("imei"),
    policyNumber: policyNumber ? policyNumber : localStorage.getItem("policy"),
    expirationDate: "",
    cvv: "",
    name: "",
    lastName: "",
    postalCode: "",
  });

  

  const handleCreditPaymentInputChange = (e) => {
    const { name, value } = e.target;
    const regexLetras = /[^a-zA-Z\s]/g;
    const regexNumeros = /^[0-9]*$/;
    const regexFecha = /^[0-9/]*$/;

    if (name === 'name' || name === 'lastName') {
      // Filtrar el valor para permitir solo letras y espacios
      const newValue = value.replace(regexLetras, '');
      if (name === 'name') {
        setValor(newValue); 
      } else if (name === 'lastName') {
        setValor2(newValue); 
      }
    } else if (name === 'accountNumber' || name === 'cvv' || name === 'expirationDate') {
      if ((name === 'expirationDate' && regexFecha.test(value)) || regexNumeros.test(value)) {
        if (name === 'accountNumber') {
          setValor3(value);  
        } else if (name === 'cvv') {
          setValor5(value);
        } else if (name === 'expirationDate') {
          setValor4(value);
        }
      }
    }
    setUserDataIncomplete("");
    setIsCvvInvalid(false);
    setIsTCDate(false);
    setIsPostalCodeInvalid(false);
    setIsCardNumberInvalid(false);
    setIsNameInvalid(false);
    setIsLastNameInvalid(false);
    setCreditPaymentForm({
      ...creditPaymentForm,
      [e.target.name]: e.target.value,
    });
    //console.log("Credit Payment Form: ", creditPaymentForm);
  };

  const [cashPaymentForm, setcashPaymentForm] = useState({
    name: "",
    phone: "",
    email: "",
    zip: "",
    address: "",
  });


  async function processPayment() {
    console.log("A) Processing payment...");
    setIsLoading(true);
    const requestCreateCard = {
      account: Number(creditPaymentForm.accountNumber),
      expiration: creditPaymentForm.expirationDate, // MM/YY format
      cvv: Number(creditPaymentForm.cvv),
      nombre: creditPaymentForm.name + ' ' + creditPaymentForm.lastName
    };
    // Convert to JSON string and encode in Base64
    const jsonStr = JSON.stringify(requestCreateCard);
    const encodedBody = btoa(jsonStr);
    try {

      const restOperation = post({
        apiName: "ppmrestapi",
        path: "/create-card",
        options: {
          body: encodedBody,
        },
      });


      const { body } = await restOperation.response;
      const data = await body.json();


      if (data.code === "0") {
        try {
          const request_confirm_payment_data = {
            token: data.data.token,
            policyUuid: localStorage.getItem("policy")
          };
          console.log(
            "request_confirm_payment_data ",
            request_confirm_payment_data
          );
          // Convert to JSON string and encode in Base64
          const jsonStr = JSON.stringify(request_confirm_payment_data);
          const encodedBody = btoa(jsonStr);
          console.log('encodedBody',encodedBody)

          const restOperation = post({
            apiName: "ppmrestapi",
            path: "/charge",
            options: {
              body: encodedBody,
            },
          });
          console.log('restOperation',restOperation)

          const { body } = await restOperation.response;
          const response = await body.json();

          console.log("confirm response1 ", response);
          console.log("confirm response ", response.data.data.cargo.redireccion.url);
          if (response.code === "0") {
            localStorage.setItem("paymentConfirmed", true);
            localStorage.setItem(
              "titular",
              `${creditPaymentForm.name} ${creditPaymentForm.lastName}`
            );
            localStorage.setItem(
              "lastFourDigits",
              creditPaymentForm.accountNumber.toString().slice(-4)
            );
            setIsLoading(true);
            dispatch({
              type: "SET_TITULAR",
              payload: `${creditPaymentForm.name} ${creditPaymentForm.lastName}`,
            });
            navigate("/gracias-por-tu-compra");
          }
          if (response.code === "-1") {
            window.location.href = response.data.data.cargo.redireccion.url
          }

        } catch (e) {
          console.log("POST call failed: ", JSON.parse(e.response.body));
          const errorBody = JSON.parse(e.response.body);
          console.log("Error body: ", errorBody.message);
          setErrorMessage(errorBody.message)
          setTriggerError(true);
          setIsLoading(false);
        }
        setIsLoading(false);
        setconfirmTcPaymentDetails({
          tokenPayment: data.token,

        });
        // setIsPaymentInitiated(true);
        /* let scriptThread = document.createElement("script");
        scriptThread.type = "text/javascript";
        scriptThread.innerHTML = `threatmetrix.profile("h.online-metrix.net", "${data.data.orgID}", "${data.data.webSessionID}");`;
        document.body.appendChild(scriptThread); */
        // P("h.online-metrix.net", data.data.orgID, data.data.sessionKey);
      }
    } catch (e) {
      console.log("POST token failed: ", JSON.parse(e.response.body));
      const errorBody = JSON.parse(e.response.body);
      console.log("Error body: ", errorBody);
      setErrorMessage(errorBody.message)
      setTriggerError(true);
      setIsLoading(false);
    }
  }

  async function processCashPayment() {
    console.log("getting proposal...");
    const policy = localStorage.getItem("policy");
    try {
      const restOperation = get({
        apiName: "ppmrestapi",
        path: `/cashpayment/${policy}`,
      });

      const { body } = await restOperation.response;
      const data = await body.json();
      //console.log("DD ", data);

      if (data.payment_status === "pending_payment") {
        const amount = data.amount; //amount": 114900,
        const newAmount = Math.floor(amount / 100); // newAmount will be 1149
        localStorage.setItem("cash_amount", newAmount);
        localStorage.setItem("cash_titular", data.customer_info.name);
        localStorage.setItem("cash_email", data.customer_info.email);
        localStorage.setItem("comission", data.comission);
        localStorage.setItem(
          "cash_id",
          data.charges.data[0].payment_method.reference
        );
        localStorage.setItem("expirationDate", data.expire_date);
        navigate("/pago-efectivo");
        setconfirmTcPaymentDetails({
          sessionKey: data.sessionKey,
          webSessionID: data.webSessionID,
        });
      }
    } catch (e) {
      console.log("POST call failed: ", JSON.parse(e.response.body));
      const errorBody = JSON.parse(e.response.body);
      console.log("Error body: ", errorBody);

      // if (errorBody.code === "002" || errorBody.code === "003") {
      //   setPolicyException(true);
      //   setTriggerErrorMessage(errorBody.message);
      // }
      if (errorBody.code === "030") {
        setCashPaymentError(true);
        setCashPaymentErrorMessage(errorBody.message);
      }
    }
  }
  async function processCashPayment2() {
    console.log("getting proposal...");
    const policy = localStorage.getItem("policy");

    try {
      const restOperation = get({
        apiName: "ppmrestapi",
        path: `/sicatel/${policy}`,
      });

      const { body } = await restOperation.response;
      const dataJson = await body.json();
      const data = dataJson.data;
      console.log('data sicatel',data)

      if (data.reference) {
        const amount = data.amount; //amount": 114900,
        const newAmount = Math.floor(amount / 100); // newAmount will be 1149
        localStorage.setItem("cash_amount", amount);
        localStorage.setItem("cash_titular", data.customer_info.name);
        localStorage.setItem("cash_email", data.customer_info.email);
        localStorage.setItem("cash_id", data.reference);
        //localStorage.setItem("comission", data.comission);
        //localStorage.setItem("expirationDate", data.expire_date);
        localStorage.setItem("barcode", data.barcode);
        navigate("/pago-ventanilla");
        //setconfirmTcPaymentDetails({ sessionKey: data.sessionKey, webSessionID: data.webSessionID, });
      }
    } catch (e) {
      console.log("POST call failed: ", JSON.parse(e.response.body));
      const errorBody = JSON.parse(e.response.body);
      console.log("Error body: ", errorBody);

      // if (errorBody.code === "002" || errorBody.code === "003") {
      //   setPolicyException(true);
      //   setTriggerErrorMessage(errorBody.message);
      // }
      if (errorBody.code === "030") {
        setCashPaymentError(true);
        setCashPaymentErrorMessage(errorBody.message);
      }
    }
  }
  async function processCashPayment3() {
    console.log("getting proposal...");
    const policy = localStorage.getItem("policy");

    try {
      const restOperation = get({
        apiName: "ppmrestapi",
        path: `/cash-distributor/${policy}`,
      });

      const { body } = await restOperation.response;
      const dataJson = await body.json();
      const data = dataJson.data;
      console.log('data distribuitor',data)

      if (data.reference) {
        const amount = data.amount; 
        const newAmount = Math.floor(amount / 100); 
        localStorage.setItem("cash_amount", amount);
        localStorage.setItem("cash_titular", data.customer_info.name);
        localStorage.setItem("cash_email", data.customer_info.email);
        localStorage.setItem("cash_imei", data.customer_info.imei);
        localStorage.setItem("cash_id", data.reference);
        navigate("/comprobante-recepcion-pago");
        //setconfirmTcPaymentDetails({ sessionKey: data.sessionKey, webSessionID: data.webSessionID, });
      }
    } catch (e) {
      console.log("POST call failed: ", JSON.parse(e.response.body));
      const errorBody = JSON.parse(e.response.body);
      console.log("Error body: ", errorBody);

      // if (errorBody.code === "002" || errorBody.code === "003") {
      //   setPolicyException(true);
      //   setTriggerErrorMessage(errorBody.message);
      // }
      if (errorBody.code === "030") {
        setCashPaymentError(true);
        setCashPaymentErrorMessage(errorBody.message);
      }
    }
  }
  // const [credit_payment, setCreditPayment] = useState(true);
  const [valor, setValor] = useState('');
  const [valor2, setValor2] = useState('');
  const [valor3, setValor3] = useState('');
  const [valor4, setValor4] = useState('');
  const [valor5, setValor5] = useState('');
  const [credit_payment, setCreditPayment] = useState(false);
  // const [cash_payment, setCashPayment] = useState(false);
  const [cash_payment, setCashPayment] = useState(false);
  // const [cash_payment2, setCashPayment2] = useState(false);
  // const [cash_payment3, setCashPayment3] = useState(false);
  const [isCvvInvalid, setIsCvvInvalid] = useState(false);
  const [isTCDate, setIsTCDate] = useState(false);
  const [isPostalCodeInvalid, setIsPostalCodeInvalid] = useState(false);
  const [isCardNumberInvalid, setIsCardNumberInvalid] = useState(false);
  const [isNameInvalid, setIsNameInvalid] = useState(false);
  const [isLastNameInvalid, setIsLastNameInvalid] = useState(false);

  const validation = (name, value) => {
    if (name === "date") {
      const datePattern = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;
      return datePattern.test(value);
    } else if (name === "postalCode") {
      const postalCodePattern = /^[0-9]{5}$/;
      return postalCodePattern.test(value);
    } else if (name === "cardNumber") {
      const cardNumberPattern = /^[0-9]{14,19}$/;
      return cardNumberPattern.test(value);
    } else if (name === "name" || name === "lastname") {
      const namePattern = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
      return namePattern.test(value);
    }
    // Add more validation checks for other fields here
  };

  const handleSubmit = (e) => {
    console.log("SUBMITING...");
    let isValid = true;
    e.preventDefault();
    if (!terminosYcondiciones) {
      setEnabledBTN(true);
      return;
    }

    if (credit_payment) {
      console.log("Processing data");

      if (
        !creditPaymentForm.cvv ||
        creditPaymentForm.cvv.length < 3 ||
        creditPaymentForm.cvv.length > 4
      ) {
        console.log("Invalid CVV");
        setIsCvvInvalid(true);
        isValid = false;
      }

      if (!validation("date", creditPaymentForm.expirationDate)) {
        setIsTCDate(true);
        isValid = false;
      }

      /* if (!validation("postalCode", creditPaymentForm.postalCode)) {
        console.log("Invalid Postal Code");
        setIsPostalCodeInvalid(true);
        isValid = false;
      } */

      if (!validation("cardNumber", creditPaymentForm.accountNumber)) {
        console.log("Invalid Card Number");
        setIsCardNumberInvalid(true);
        isValid = false;
      }

      if (!validation("name", creditPaymentForm.name)) {
        console.log("Invalid Card Number");
        setIsNameInvalid(true);
        isValid = false;
      }

      if (!validation("lastname", creditPaymentForm.lastName)) {
        console.log("Invalid Card Number");
        setIsLastNameInvalid(true);
        isValid = false;
      }

      if (!isValid) {
        return;
      }
      isUserDataComplete && creditPaymentForm.policyNumber !== ""
        ? processPayment().then(() => {
          console.log("B) Payment Processed");
          //setIsPaymentInitiated(true);
        })
        : setUserDataIncomplete(
          "Por favor, completa tus datos personales antes de continuar"
        );
    } else if (cash_payment) {
      console.log("Cash Payment");
      //isUserDataComplete ? processCashPayment() : console.log("NOT YET");
      if (isUserDataComplete) {
        setIsLoading(true);
        processCashPayment().finally(() => setIsLoading(false));
      }
    } 
    // else if (cash_payment2) {
    //   console.log("Pago en caja");
    //   if (isUserDataComplete) {
    //     setIsLoading(true);
    //     processCashPayment2().finally(() => setIsLoading(false));
    //   }
    // } else if (cash_payment3) {
    //   console.log("Pago en efectivo");
    //   if (isUserDataComplete) {
    //     setIsLoading(true);
    //     processCashPayment3().finally(() => setIsLoading(false));
    //   }
    // }
  };

  const handleCheckbox = (e) => {
    //e.preventDefault();
    setCreditPayment(!credit_payment);
    setCashPayment(!cash_payment);
  };

  /*   useEffect(() => {
      if (isPaymentInitiated) {
        setIsLoading(true);
        sendPaymentRequest().finally(() => setIsLoading(false));
        setIsPaymentInitiated(false); // Reset the flag
      }
      // let isUserDataComplete = JSON.parse(
      //   localStorage.getItem("userDataComplete") || "false"
      // );
    }, [isPaymentInitiated]); */

  const [paymentMethod, setPaymentMethod] = useState(''); // Valor por defecto: tarjeta

  const handlePaymentMethodChange = (event) => {
    console.log('event.target.value: ', event);
    setPaymentMethod(event.target.value);
    if (event.target.value === 'card') {
    setCreditPayment(true);
    setCashPayment(false);
    // setCashPayment2(false);
    // setCashPayment3(false);
    } else if (event.target.value === 'oxxo') {
    setCreditPayment(false);
    setCashPayment(true);
    // setCashPayment2(false);
    // setCashPayment3(false);
    } 
    // else if (event.target.value === 'cash') {
    // setCreditPayment(false);
    // setCashPayment(false);
    // setCashPayment2(true);
    // setCashPayment3(false);
    // } else if (event.target.value === 'cash2') {
    // setCreditPayment(false);
    // setCashPayment(false);
    // setCashPayment2(false);
    // setCashPayment3(true);
    // }
  };
  //useEffect(() => {
  //processCashPayment2().finally(() => setIsLoading(false));
  //},[]);
  // const handleCaptchaClick = () => {
  //   if (!isCaptchaResolved) {
  //     setShowCaptcha(true); // Solo mostrar el CAPTCHA si no está resuelto
  //   }
  // };

  // const handleCaptchaResolved = () => {
  //   setIsCaptchaResolved(true);
  //   setShowCaptcha(false);
  // };


  const mtd = localStorage.getItem("mtd");
  const mtdObj =  mtd ? JSON.parse(mtd) : null;

  const isDistributor = mtdObj && Object.keys(mtdObj).length > 0;

  // const reg =  mtdObj?.region;

  // const listDist =
  //   ["SRVPUEB21",
  //     "SRVPUEB22",
  //     "SRVPUEB23",
  //     "SRVPUEB24",
  //     "SRVPUEB25",
  //     "SRVPUEB26",
  //     "SRVPUEB27",
  //     "SRVPUEB28",
  //     "SOLCO"]

  // const DistVal = mtdObj?.fzaVta;

  // const DistIsVal = listDist.includes(DistVal)
  return (
    <div className="flex flex-col items-center justify-center my-2 text-primary">
      {isLoading ? (
        <TransparentSpinner />
      ) : (
        <form
          onSubmit={handleSubmit}
          className=" w-full mx-auto bg-[#fff7e9] rounded p-5"
        >
          <h1 className="text-[#6D80A4] text-lg mb-4">
            Elige como quieres realizar tu pago:
          </h1>
          <div className="flex mb-4 ">
            <input
              id="card"
              name="card"
              value="card"
              type="radio"
              checked={paymentMethod === 'card'}
              onChange={handlePaymentMethodChange}
              className=" indeterminate:bg-gray-300 mr-2"
            />
            <label
              htmlFor="credit_payment"
              className="text-[0.8rem] text-[#6D80A4] font-bold"
            >
              Tarjeta de crédito o débito{" "}
              <span className="font-thin">| Visa y MasterCard</span>
            </label>
          </div>


          {credit_payment ? (
            <>
              <div className="flex flex-row mb-3">
                <div className="flex flex-col mb-2 mr-1 w-full ">
                  <label className="text-[#6D80A4] pb-1 ">
                    Nombre del Titular
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={valor}
                    onChange={handleCreditPaymentInputChange}
                    className={`text-[#6D80A4] mb-4 mr-2 w-full px-3 py-2 placeholder-gray-300 border ${isNameInvalid ? "border-red-500" : "border-gray-300"
                      } rounded-md focus:outline-none focus:border-indigo-500`}
                  />
                </div>
                <div className="flex flex-col mb-2 mr-1 w-full ">
                  <label className="text-[#6D80A4] pb-1">Apellidos</label>
                  <input
                    type="text"
                    name="lastName"
                    value={valor2}
                    onChange={handleCreditPaymentInputChange}
                    className={`text-[#6D80A4] mb-4 mr-2 w-full px-3 py-2 placeholder-gray-300 border ${isLastNameInvalid ? "border-red-500" : "border-gray-300"
                      } rounded-md focus:outline-none focus:border-indigo-500`}
                  />
                </div>
              </div>

              <div className="flex flex-row mb-3">
                <div className="flex flex-col  mr-1 w-full ">
                  <label className="text-[#6D80A4] pb-1">
                    Número de tarjeta
                  </label>
                  <input
                    type="text"
                    maxLength={16}
                    value={valor3}
                    name="accountNumber"
                    placeholder=""
                    onChange={handleCreditPaymentInputChange}
                    className={`text-[#6D80A4] mb-4 mr-2 w-full px-3 py-2 placeholder-gray-300 border ${isCardNumberInvalid ? "border-red-500" : "border-gray-300"
                      } rounded-md focus:outline-none focus:border-indigo-500`}
                  />
                </div>
                {/* <div className="flex flex-col  mr-1 w-full ">
                  {" "}
                  <label className="text-[#6D80A4] pb-1">
                    Meses Sin Intereses
                  </label>
                  <select
                    name="zip"
                    disabled={true}
                    className=" text-opacity-25 mb-4 mr-2 w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                  >
                    <option className="text-opacity-25" value="">
                      Selecciona
                    </option>
                    <option value="3">3 meses</option>
                    <option value="6">6 meses</option>
                    <option value="9">9 meses</option>
                  </select>
                </div> */}
              </div>
              <div className="flex flex-row items-center">
                <div className="flex flex-col mb-2 mr-1 w-full ">
                  <label className="text-[#6D80A4] laptop:pb-1 phone:pb-0 phone:mb-3 laptop:mb-0 phone:text-xs laptop:text-sm">
                    Fecha de vencimiento
                  </label>
                  <input
                    type="text"
                    name="expirationDate"
                    maxLength={5}
                    value={valor4}
                    placeholder="mm/aa"
                    onChange={handleCreditPaymentInputChange}
                    className={`text-[#6D80A4] mb-4 mr-2 w-full px-3 py-2 placeholder-gray-300 border ${isTCDate ? "border-red-500" : "border-gray-300"
                      } rounded-md focus:outline-none focus:border-indigo-500`}
                  />
                </div>
                <div className="flex flex-col mb-2 mr-1 w-full ">
                  <label className="text-[#6D80A4] laptop:pb-1 phone:pb-2 phone:mb-4 laptop:mb-0">
                    CVV
                  </label>
                  <div className="relative">
                    <input
                      type="password"
                      value={valor5}
                      name="cvv"
                      maxLength={4}
                      onChange={handleCreditPaymentInputChange}
                      className={`text-[#6D80A4] mb-4 mr-2 w-full px-3 py-2 placeholder-gray-300 border ${isCvvInvalid ? "border-red-500" : "border-gray-300"
                        } rounded-md focus:outline-none focus:border-indigo-500`}
                    />
                    <img
                      src={candadocvv}
                      className="absolute left-3 top-1/3 transform -translate-y-1/2 h-4 w-3"
                    />
                  </div>
                </div>

                {/* <div className="flex flex-col mb-2 mr-1 w-full ">
                  <div className="mb-1 flex flex-row justify-start items-center">
                    <div>
                      <label
                        htmlFor="name"
                        className="text-[#6D80A4] pb-1 phone:mb-4 laptop:mb-0"
                      >
                        Código Postal
                      </label>
                    </div>

                    <div className="relative inline-block group  ml-2">
                      <span className="flex items-center text-center justify-center w-3 h-3 bg-[#6D80A4] text-white rounded-full cursor-pointer text-[0.6rem]">
                        i
                      </span>
                      <div className="absolute hidden group-hover:block -top-1/2 right-full ml-2 laptop:w-[22vw] phone:w-72 p-2 bg-tooltip text-primary text-xs rounded shadow-lg">
                        El Código Postal debe coincidir con el del estado de
                        cuenta de la tarjeta.
                      </div>
                    </div>
                  </div>
                  <input
                    type="text"
                    name="postalCode"
                    onChange={handleCreditPaymentInputChange}
                    className={`text-[#6D80A4] mb-4 mr-2 w-full px-3 py-2 placeholder-gray-300 border ${isPostalCodeInvalid ? "border-red-500" : "border-gray-300"
                      } rounded-md focus:outline-none focus:border-indigo-500`}
                  />
                </div> */}
              </div>
            </>
          ) : (
            <>
            </>
          )}

          <div className="flex mb-4">
            <input
              id="oxxo"
              name="oxxo"
              value="oxxo"
              type="radio"

              checked={paymentMethod === 'oxxo'}
              onChange={handlePaymentMethodChange}
              className=" indeterminate:bg-gray-300 mr-2"
            />
            <label
              htmlFor="credit_payment"
              className="text-[0.8rem] text-[#6D80A4] font-bold"
            >
              Efectivo <span className="font-thin">| OXXO</span>
            </label>
          </div>
          {/* { regionDistInt >= rangoMinRegionDist && regionDistInt <= rangoMaxRegionDist ?
          <div className="flex mb-4">
            <input
              id="cash"
              name="cash"
              value="cash"
              type="radio"

              checked={paymentMethod === 'cash'}
              onChange={handlePaymentMethodChange}
              className=" indeterminate:bg-gray-300 mr-2"
            />
            <label
              htmlFor="credit_payment"
              className="text-[0.8rem] text-[#6D80A4] font-bold"
            >
              Ventanilla <span className="font-thin">| MTD</span>
            </label>
          </div> : 
          null
          } */}
          {/* {isDistributor && reg == 9 && DistIsVal ?  (
            <div className="flex mb-4">
              <input
                id="cash2"
                name="cash2"
                value="cash2"
                type="radio"
                checked={paymentMethod === "cash2"}
                onChange={handlePaymentMethodChange}
                className=" indeterminate:bg-gray-300 mr-2"
              />
              <label
                htmlFor="credit_payment"
                className="text-[0.8rem] text-[#6D80A4] font-bold"
              >
                Efectivo <span className="font-thin">| MTD </span>
              </label>
            </div>
          ) : null} */}

          <div className="flex flex-col items-start justify-center">
          {/* {cash_payment3 && (
              <div>
              <p className="text-[#6D80A4] text-md mb-2">Resolver el captcha para poder continuar</p>
              <div className="relative mt-2 flex items-center border border-gray-300 rounded-lg p-3">
                <button
                  type="button"
                  onClick={handleCaptchaClick}
                  className="w-full py-2  text-center text-white bg-gray-500 hover:bg-gray-600 rounded-md"
                  >
                  CAPTCHA
                </button>

                {isCaptchaResolved && (
                  <FaCheckCircle
                    className="ml-2 text-green-500" 
                    size={24}
                  />
                )}
              </div>
              </div>
            )} */}
            <div className="flex justify-start laptop:items-center phone:items-start mb-4 mt-5">
              <input
                name="credit_payment"
                type="checkbox"
                checked={terminosYcondiciones}
                onClick={() => {
                  setTerminosYcondiciones(!terminosYcondiciones);
                }}
                className=" indeterminate:bg-gray-300 mr-2 laptop:mt-0 phone:mt-[0.1rem]"
              />
              <label
                htmlFor="credit_payment"
                className="text-xs text-[#6D80A4]"
              >
                He leído y estoy de acuerdo con los{" "}
                <span className="text-[#006BBC]">
                  <a
                    href="https://www.telcel.com/personas/servicios/localizacion-y-seguridad/proteccion-movil-amigo-kit"
                    target="_blank"
                  >
                    Términos y Condiciones del servicio.
                  </a>
                </span>{" "}
              </label>
            </div>
            {!terminosYcondiciones ? (
              <div className="flex flex-row items-center justify-center pt-1 phone:w-64 laptop:w-auto">
                <img src={warningLogo} alt="" className="w-4 h-4 mr-2" />
                <span className="w-96 text-red-500 text-[0.6rem] text-left">
                  Por favor acepta términos y condiciones para proceder con el
                  pago
                </span>
              </div>
            ) : null}
          </div>
          <div className="flex justify-end">

          {/* DESCOMENTAR ESTE  */}
          {/* {!cash_payment3 && (
            <button
              disabled={!isUserDataComplete}
              type="submit"
              //onClick={handlePayClick}
              className={` ${!isUserDataComplete || !terminosYcondiciones
                ? "bg-orange-300 hover:bg-orange-300 cursor-not-allowed"
                : "bg-orng hover:bg-opacity-50 cursor-pointer"
                } laptop:w-1/4 phone:w-[28vw] px-3 py-2 text-white  rounded-md focus:outline-none"`}
            > 
              {cash_payment ? "Continuar" : "Pagar"} 
              Continuar
            </button> 
          )}  */}
          
            <button
              disabled={!isUserDataComplete}
              type="submit"
              //onClick={handlePayClick}
              className={` ${!isUserDataComplete || !terminosYcondiciones
                ? "bg-orange-300 hover:bg-orange-300 cursor-not-allowed"
                : "bg-orng hover:bg-opacity-50 cursor-pointer"
                } laptop:w-1/4 phone:w-[28vw] px-3 py-2 text-white  rounded-md focus:outline-none"`}
            > 
              {/* {cash_payment ? "Continuar" : "Pagar"}  */}
              Continuar 
            </button> 

          {/* {cash_payment3 && (
              <button
                type="submit"
                disabled={!isCaptchaResolved}
                className={` ${
                  !isUserDataComplete || !terminosYcondiciones
                    ? "bg-orange-300 hover:bg-orange-300 cursor-not-allowed"
                    : "bg-orng hover:bg-opacity-50 cursor-pointer"
                } laptop:w-1/4 phone:w-[28vw] px-3 py-2 text-white  rounded-md focus:outline-none"`}
              >

                Continuar
              </button>
            )} */}
            </div>
            {/* {showCaptcha && (
              <CaptchaComponent
                onCaptchaResolved={handleCaptchaResolved}
                onClose={() => setShowCaptcha(false)}
              />
            )} */}


          {userDataIncomplete && (
            <div className="text-center">
              <span className="text-red-500 text-xs">{userDataIncomplete}</span>
            </div>
          )}
        </form>
      )}

      <CustomErrorModal
        isVisible={triggerError}
        onClose={() => setTriggerError(false)}
      >
        <div className="py-5 px-5 laptop:px-7 text-left">
          {/* <h3 className="mb-4 text-lg font-medium text-primary text-center">
              Selecciona los siguientes datos para generar tu CURP
            </h3> */}
          <div className="flex justify-center mb-4 mt-4">
            <img src={tcFallido} alt="" className="text-center w-13 h-11" />
          </div>
          <h1 className="text-center text-lg text-[#6D80A4] font-semibold mb-2">
            Pago no realizado
          </h1>
          <div className="laptop:w-96 phone:w-64 laptop:w-[20vw] rounded-full h-[0.3rem] bg-[#F45753] bg-opacity-25 mb-3"></div>
          <div className="flex justify-center tems-center laptop:w-[22vw] phone:w-64 ">
            <p className="  mb-4 text-base font-regular text-[#6D80A4] text-center">
              {errorMessage}
            </p>
          </div>
          {/* <p className="mb-4 text-lg font-regular text-primary text-center">
            Por favor, verifícalo e intenta de nuevo.
          </p> */}

          <div
            className="space-y-0 flex flex-row justify-center items-center"
            action="#"
          >
            <button
              onClick={() => {
                setTriggerError(false);
                setCreditPayment(false);
                setCashPayment(true);
                // navigate("/");
              }}
              className="laptop:w-[50vw] phone:w-[40vw] text-primary border border-primary border-1 bg-white hover:bg-gray-100 
              focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
              rounded-md text-sm px-5 phone:px-1 laptop:py-2 phone:py-1 text-center phone:mr-2"
            >
              Pago en efectivo
            </button>

            <button
              onClick={() => {
                setTriggerError(false);
              }}
              className="laptop:w-[50vw] phone:w-[40vw] text-white bg-[#6D80A4] hover:bg-opacity-85
              focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
              rounded-lg text-sm px-5 phone:px-1 laptop:py-2 phone:py-1 text-center"
            >
              Intenta de nuevo
            </button>
          </div>
        </div>
      </CustomErrorModal>
      <CustomErrorModal
        isVisible={terminosTriggerError}
        onClose={() => setTerminosTriggerError(false)}
      >
        <div className="py-5 px-5 laptop:px-7 text-left">
          {/* <h3 className="mb-4 text-lg font-medium text-primary text-center">
              Selecciona los siguientes datos para generar tu CURP
            </h3> */}
          <div className="flex justify-center mb-4 mt-4">
            <img src={denegated} alt="" className="text-center w-10 h-10" />
          </div>
          <h1 className="text-center text-lg text-[#6D80A4] font-semibold mb-2">
            Términos y Condiciones
          </h1>
          <div className="laptop:w-96 phone:w-64 laptop:w-[20vw] rounded-full h-[0.3rem] bg-[#F45753] bg-opacity-25 mb-3"></div>
          <p className="mb-4 text-base font-regular text-[#6D80A4] text-center">
            Por favor, acepta los términos y condiciones del servicio para poder
            continuar.
          </p>
          {/* <p className="mb-4 text-lg font-regular text-primary text-center">
            Por favor, verifícalo e intenta de nuevo.
          </p> */}
          <form
            className="space-y-6 flex flex-row justify-center items-center"
            action="#"
          >
            <button
              type="submit"
              onClick={() => setTerminosTriggerError(false)}
              className="laptop:w-[15vw] phone:w-[50vw] text-white bg-[#6D80A4] hover:bg-primary
              focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
              rounded-lg text-sm px-5 phone:px-3 py-2.5 text-center"
            >
              Aceptar
            </button>
          </form>
        </div>
      </CustomErrorModal>
      <CustomErrorModal
        isVisible={cashPaymentError}
        onClose={() => setCashPaymentError(false)}
      >
        <div className="py-5 px-5 laptop:px-7 text-left">
          {/* <h3 className="mb-4 text-lg font-medium text-primary text-center">
              Selecciona los siguientes datos para generar tu CURP
            </h3> */}
          <div className="flex justify-center mb-4 mt-4">
            <img src={warningLogo} alt="" className="text-center w-10 h-10" />
          </div>
          <h1 className="text-center text-lg text-[#6D80A4] font-semibold mb-2">
            Pago no disponible
          </h1>
          <div className="laptop:w-96 phone:w-64 laptop:w-[20vw] rounded-full h-[0.3rem] bg-[#F45753] bg-opacity-25 mb-3"></div>
          <p className="mb-4 text-base font-regular text-[#6D80A4] text-center">
            Lo sentimos, el pago en efectivo no está disponible en este momento.
            Por favor, intenta de nuevo más tarde.
          </p>
          {/* <p className="mb-4 text-lg font-regular text-primary text-center">
            Por favor, verifícalo e intenta de nuevo.
          </p> */}
          <form
            className="space-y-6 flex flex-row justify-center items-center"
            action="#"
          >
            <button
              type="submit"
              onClick={() => setCashPaymentError(false)}
              className="laptop:w-[15vw] phone:w-[50vw] text-white bg-[#6D80A4] hover:bg-primary
              focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
              rounded-lg text-sm px-5 phone:px-3 py-2.5 text-center"
            >
              Aceptar
            </button>
          </form>
        </div>
      </CustomErrorModal>
    </div>
  );
}
